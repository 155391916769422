import mobileApp from "../assets/icons/mobile-app.svg";
import webApp from "../assets/icons/web-app.svg";
import devOps from "../assets/icons/devops.svg";
import desktop from "../assets/icons/desktop.svg";
import cctv from "../assets/icons/cctv.svg";
import security from "../assets/icons/security.svg";
import vehicle from "../assets/icons/vehicle.svg";
import ecommerce from "../assets/icons/ecommerce.svg";
import emailNotification from "../assets/icons/email-notification.svg";
import smsNotification from "../assets/icons/sms-notification.svg";
import socialMedia from "../assets/icons/social-media.svg";
import apiDevelopment from "../assets/icons/api-development.svg";
import marketing from "../assets/icons/marketing.svg";
import callCenter from "../assets/icons/call-center.svg";

export const ServicesData = [
  {
    id: "1",
    icon: mobileApp,
    title: "Mobile Application",
    list: [
      { pointList: "Mobile Applications." },
      { pointList: "Versatile Functions." },
      { pointList: "Modern Integration." },
      { pointList: "Cross-Platform Flutter." },
      { pointList: "Visual Appeal." },
      { pointList: "Android Native." },
      { pointList: "Feature-Rich Apps." },
      { pointList: "iOS Native." },
      { pointList: "Apple Ecosystem." },
      { pointList: "User-Centric Experiences." },
    ],
    url: "#",
  },
  {
    id: "2",
    icon: webApp,
    title: "Web Application",
    list: [
      { pointList: "Web Applications." },
      { pointList: "Accessible via Browsers." },
      { pointList: "Dynamic Experiences." },
      { pointList: "React.js: Component-Based." },
      { pointList: "Virtual DOM Optimization." },
      { pointList: "ASP.NET." },
      { pointList: "C# or VB.NET." },
      { pointList: "PHP Web Apps." },
      { pointList: "Server-Side Scripting." },
      { pointList: "WordPress: CMS Solution." },
    ],
    url: "#",
  },
  {
    id: "3",
    icon: desktop,
    title: "Desktop Application",
    list: [
      { pointList: "Desktop Applications." },
      { pointList: "Offline Functionalities." },
      { pointList: "Windows Apps." },
      { pointList: "Versatile Features." },
      { pointList: "Linux Servers." },
      { pointList: "Stability, Security." },
      { pointList: "Client-Side Processing." },
      { pointList: "HTML, CSS, JavaScript." },
      { pointList: "Linux on Devices." },
      { pointList: "macOS: Apple OS." },
    ],
    url: "#",
  },
  {
    id: "4",
    icon: devOps,
    title: "DevOps",
    list: [
      { pointList: "CI/CD Pipelines." },
      { pointList: "Automation Tools." },
      { pointList: "Infrastructure Management." },
      { pointList: "Release Orchestration." },
      { pointList: "Cloud Deployment." },
      { pointList: "Monitoring Setup." },
      { pointList: "Configuration Management." },
      { pointList: "Security Integration." },
      { pointList: "Performance Tuning." },
      { pointList: "Collaboration Platforms." },
    ],
    url: "#",
  },
  {
    id: "5",
    icon: security,
    title: "Security Services (Guards & Escorts)",
    list: [
      { pointList: "Ensure safety." },
      { pointList: "Policy-based monitoring." },
      { pointList: "Guarded building access." },
      { pointList: "Availability & accountability." },
      { pointList: "Authentication." },
      { pointList: "Military-managed security." },
      { pointList: "Guards, VVIP, technology." },
      { pointList: "24/7 protection" },
      { pointList: "Drones, tracking, control." },
      { pointList: "Asset security." },
    ],
    url: "#",
  },
  {
    id: "6",
    icon: vehicle,
    title: "Vehicle Tracking, Fleet & Eqpt Monitoring",
    list: [
      { pointList: "Monitor Usage." },
      { pointList: "Details Management." },
      { pointList: "Automated Alerts." },
      { pointList: "Equipment Restoration." },
      { pointList: "Real-Time Monitoring." },
      { pointList: "Route Information." },
      { pointList: "Performance Tracking." },
      { pointList: "Fix Tracking Zones" },
      { pointList: "Data Insights." },
      { pointList: "System Compatibility." },
    ],
    url: "#",
  },
  {
    id: "7",
    icon: ecommerce,
    title: "E Commerce Integration",
    list: [
      { pointList: "Data Transfer." },
      { pointList: "Accounting, Sales." },
      { pointList: "CRM, Marketing Included." },
      { pointList: "Streamlined Operations." },
      { pointList: "Improved Communication." },
      { pointList: "Revenue Enhancement." },
      { pointList: "Buying, Selling Online." },
      { pointList: "Digital Sales Integration" },
      { pointList: "Bank Account Management." },
      { pointList: "Timely Payments." },
    ],
    url: "#",
  },
  {
    id: "8",
    icon: emailNotification,
    title: "Email Notification Integration",
    list: [
      { pointList: "Information Updates." },
      { pointList: "Subscriber Communication." },
      { pointList: "New Products, Features." },
      { pointList: "Promotions, Maintenance." },
      { pointList: "ERP Integration." },
      { pointList: "High Open Rates." },
      { pointList: "Customer Preparedness." },
      { pointList: "Boosts Server Reputation." },
      { pointList: "Enhanced Deliverability." },
      { pointList: "Customer Retention." },
    ],
    url: "#",
  },
  {
    id: "9",
    icon: smsNotification,
    title: "SMS Notification Integration",
    list: [
      { pointList: "Push & SMS Notifications." },
      { pointList: "Customer Engagement." },
      { pointList: "SMS Alerts." },
      { pointList: "Text Alerts." },
      { pointList: "Urgent, Non-Urgent." },
      { pointList: "Large Group Messages." },
      { pointList: "SMS Bundles." },
      { pointList: "Advertisements, Progress." },
      { pointList: "Telecom Providers." },
      { pointList: "Business Updates." },
    ],
    url: "#",
  },
  {
    id: "10",
    icon: socialMedia,
    title: "Social Media Integration",
    list: [
      { pointList: "Social Media Integration." },
      { pointList: "Connecting Brands." },
      { pointList: "Marketing Extension." },
      { pointList: "Time-Saving Solution." },
      { pointList: "Diverse Ecosystem." },
      { pointList: "Automation Key." },
      { pointList: "Social Share Buttons." },
      { pointList: "Social Login." },
      { pointList: "Social Photos, Videos." },
      { pointList: "Expand Network." },
    ],
    url: "#",
  },
  {
    id: "11",
    icon: apiDevelopment,
    title: "API Development & Integration",
    list: [
      { pointList: "API Development." },
      { pointList: "Software Integration." },
      { pointList: "Data Communication." },
      { pointList: "Efficient Connectivity." },
      { pointList: "API Bridges Systems." },
      { pointList: "Streamlines Workflows." },
      { pointList: "Enhances User Experience." },
      { pointList: "Backend Development." },
      { pointList: "Server-side Components." },
      { pointList: "Online Services." },
    ],
    url: "#",
  },

  {
    id: "12",
    icon: marketing,
    title: "Social Media Marketing",
    list: [
      { pointList: "Easier Communication." },
      { pointList: "Global Village." },
      { pointList: "Social Media Marketing." },
      { pointList: "Platforms & Goals." },
      { pointList: "Engaging Campaigns." },
      { pointList: "Measure Buzz." },
      { pointList: "Successful Examples." },
      { pointList: "Management Tools." },
      { pointList: "Benefits & Impact." },
      { pointList: "Social Media Types." },
    ],
    url: "#",
  },
  {
    id: "13",
    icon: callCenter,
    title: "Unified Call Center",
    list: [
      { pointList: "Efficient Service Desk." },
      { pointList: "Productive Solution." },
      { pointList: "Incident Awareness." },
      { pointList: "Performance Tracking." },
      { pointList: "24x7 Support." },
      { pointList: "Hierarchical Escalation." },
      { pointList: "Change Management." },
      { pointList: "Service Levels." },
      { pointList: "Customer Experience." },
      { pointList: "Network Monitoring." },
    ],
    url: "#",
  },
  {
    id: "14",
    icon: cctv,
    title: "CCTV Planning & Implementation",
    list: [
      { pointList: "CCTV prevents crimes." },
      { pointList: "Continuous recording, access." },
      { pointList: "Property & employee safety." },
      { pointList: "Reduces crime rates." },
      { pointList: "Effective against vehicle crime." },
      { pointList: "Plate detection." },
      { pointList: "Smart city surveillance." },
      { pointList: "Observes suspicious activity." },
      { pointList: "Integrated security." },
      { pointList: "24/7 asset protection." },
    ],
    url: "#",
  }
];
